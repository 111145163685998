import ReactGA from 'react-ga4'
import { TCartItem } from '@lib/types'
import { LupaProduct } from '@lib/types/lupa'
import { ItemData } from '@lib/types/product'
import parseProductPrices from '@lib/utils/price/parseProductPrices'

type ProductDataType = LupaProduct[] | ItemData | TCartItem[]

export const handleGoogleAnalytics = (
  event: Gtag.EventNames,
  {
    productData,
    searchQuery,
    coupon,
  }: {
    productData?: ProductDataType
    searchQuery?: string
    coupon?: string
  }
) => {
  switch (event) {
    case 'view_item_list': {
      return sendLupaProductData(event, productData as LupaProduct[])
    }
    case 'add_to_wishlist':
    case 'add_to_cart':
    case 'select_item':
    case 'view_item': {
      return sendBasicProductData(event, productData as ItemData)
    }
    case 'add_shipping_info':
    case 'add_payment_info':
    case 'remove_from_cart':
    case 'begin_checkout':
    case 'view_cart':
    case 'purchase': {
      return sendCartProductData(event, productData as TCartItem[], coupon)
    }
    case 'view_search_results': {
      return sendSearchData(event, searchQuery ?? '')
    }
  }
}

const sendLupaProductData = (
  event: Gtag.EventNames,
  products: LupaProduct[]
) => {
  ReactGA.event(event, {
    items: products.map((product) => ({
      item_id: product.id,
      item_name: product.name,
    })),
  })

  ReactGA.send({
    event_category: 'Product List',
    event_label: event,
    hitType: 'event',
    eventAction: event,
    items: products.map((product) => ({
      item_id: product.id,
      item_name: product.name,
    })),
  })
}

const sendBasicProductData = (event: Gtag.EventNames, product: ItemData) => {
  ReactGA.send({
    event_category: 'Product',
    event_label: event,
    hitType: 'event',
    eventAction: event,
    currency: 'EUR',
    value: product?.price / 100,
    items: [
      {
        item_id: product.id,
        item_name: product.name,
        item_list_name: 'product',
      },
    ],
  })
}

const sendCartProductData = (
  event: Gtag.EventNames,
  products: TCartItem[],
  coupon?: string
) => {
  const value = products.reduce((total, product) => {
    const { currentPrice } = parseProductPrices({
      currentPrice: product.unitPrice,
      originalPrice: product.originalUnitPrice,
    })
    if (!currentPrice || !product.quantity) {
      return total
    }

    return total + currentPrice * product.quantity
  }, 0)

  ReactGA.send({
    event_category: 'Cart Product List',
    event_label: event,
    hitType: 'event',
    eventAction: event,
    currency: 'EUR',
    value: value / 100,
    ...(coupon && { coupon }),
    items: products.map((product) => ({
      item_id: product.id,
      item_name: product.productName,
      price: (product.unitPrice || 0) / 100,
      quantity: product.quantity,
    })),
  })
}

const sendSearchData = (event: Gtag.EventNames, query: string) => {
  ReactGA.event(event, {
    search_term: query,
  })
}
