export enum EModalView {
  Login = 'LOGIN_VIEW',
  Forgot = 'FORGOT_VIEW',
  SizeCompare = 'SIZE_COMPARE_VIEW',
  QuickBuy = 'QUICK_BUY_VIEW',
  AddressSelect = 'ADDRESS_SELECT_VIEW',
  AccountRegisterConfirmation = 'ACCOUNT_REGISTER_CONFIRMATION_VIEW',
}

export enum EActionType {
  OpenSidebar = 'OPEN_SIDEBAR',
  CloseSidebar = 'CLOSE_SIDEBAR',
  OpenDropdown = 'OPEN_DROPDOWN',
  CloseDropdown = 'CLOSE_DROPDOWN',
  OpenModal = 'OPEN_MODAL',
  CloseModal = 'CLOSE_MODAL',
  SetModalView = 'SET_MODAL_VIEW',
  SetSidebarView = 'SET_SIDEBAR_VIEW',
  SetUserAvatar = 'SET_USER_AVATAR',
  SetNotificationView = 'SET_NOTIFICATION_VIEW',
  OpenNotification = 'OPEN_NOTIFICATION',
  CloseNotification = 'CLOSE_NOTIFICATION',
  OpenSearch = 'OPEN_SEARCH',
  CloseSearch = 'CLOSE_SEARCH',
}

export enum ESidebarView {
  MobileMenu = 'MOBILE_MENU_VIEW',
  Cart = 'CART_VIEW',
}

export enum EProductLabel {
  New = 'new',
  Discount = 'discount',
}

export enum EDiscountLabelDisplayMode {
  Full = 'full',
  Percent = 'percent',
  None = 'none',
}

export const SCROLL_UP = 'up'
export const SCROLL_DOWN = 'down'

export enum ENotificationIds {
  Newsletter = 'newsletter',
  CustomerAddresses = 'customer-addresses',
  PasswordChange = 'password-change',
  CustomerProfile = 'customer-profile',
  CustomerRegister = 'customer-register',
  CheckoutError = 'checkout-error',
  LoginError = 'login-error',
  ContactUs = 'contact-us',
  OrderReturn = 'order-return',
  CreateReturnError = 'create-return-error',
}
