import { Channel } from '@lib/types'
import { axiosInstance } from '@lib/utils/axios'
import { getRoute } from '@lib/utils/getRoute'
import { CHANNEL_ROUTE } from './routes'

interface GetChannelParams {
  code?: string
}

export const getChannel = async ({ code }: GetChannelParams) => {
  if (!code) {
    throw new Error('Channel code is required to fetch channel details')
  }

  const response = await axiosInstance.get<Channel>(
    getRoute(CHANNEL_ROUTE, {
      code,
    })
  )

  return response.data
}
