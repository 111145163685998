import cn from 'clsx'
import { FC } from 'react'
import { useClientTranslation } from '@app/i18n/client'

interface Props {
  isCartItem?: boolean
}
const ProductOutOfStockLabel: FC<Props> = ({ isCartItem }) => {
  const { t } = useClientTranslation('common')

  return (
    <div
      className={cn(
        'absolute inset-0 z-[9] flex w-full items-center bg-black/20 font-bold uppercase',
        isCartItem ? 'text-[10px]' : 'text-xl'
      )}
    >
      <div className="flex w-full justify-center bg-white/50 py-2">
        {t('Out of stock')}
      </div>
    </div>
  )
}

export default ProductOutOfStockLabel
