export const getRoute = (
  route: string,
  urlParameters?: Record<string, string | number>
) => {
  let finalRoute = route

  if (!urlParameters) {
    return finalRoute
  }

  for (const [urlParametersKey, urlParameterValue] of Object.entries(
    urlParameters
  )) {
    finalRoute = finalRoute.replace(
      `{${urlParametersKey}}`,
      urlParameterValue.toString()
    )
  }

  return finalRoute
}
