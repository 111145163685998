interface Params {
  currentPrice?: number
  originalPrice?: number | null
}

export default ({ currentPrice, originalPrice }: Params) => {
  const lowerBound =
    originalPrice != currentPrice ? originalPrice : currentPrice

  if (lowerBound && originalPrice && lowerBound !== currentPrice) {
    return {
      currentPrice,
      originalPrice,
    }
  }

  return {
    currentPrice,
  }
}
