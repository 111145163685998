import { useQuery } from '@tanstack/react-query'
import { getChannel } from '@services/channel/api'

const defaultChannelCode = process.env.CHANNEL_CODE

interface ChannelParams {
  code?: string
}

export const useChannel = (
  { code = defaultChannelCode }: ChannelParams = {
    code: defaultChannelCode,
  }
) =>
  useQuery(
    ['channel', code],
    () =>
      getChannel({
        code,
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    }
  )
