import cn from 'clsx'
import { FC } from 'react'
import { convertToPrice } from '@lib/utils/price/convertToPrice'

type Props = {
  originalPrice: number | undefined | null
  price?: number | undefined
  className?: string
}

const ProductPrice: FC<Props> = ({ originalPrice = 0, price, className }) => {
  const isLowerPrice = (price ?? 0) < (originalPrice ?? 0)

  return (
    <div className={cn('flex flex-wrap', className)}>
      <span
        className={cn(
          'm-1 font-medium',
          isLowerPrice ? 'text-red' : 'text-black'
        )}
      >
        {convertToPrice(price ?? originalPrice ?? 0)}
      </span>
      {isLowerPrice && originalPrice && (
        <span className="m-1 text-secondary line-through">
          {convertToPrice(originalPrice ?? 0)}
        </span>
      )}
    </div>
  )
}

export default ProductPrice
