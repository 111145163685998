'use client'

import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'
import { ENotificationView } from '@lib/constants/notification'
import {
  EActionType,
  EModalView,
  ENotificationIds,
  ESidebarView,
} from '@lib/constants/ui'
import { ProductCardData } from '@lib/types/product'

export type TModalProps = {
  product?: ProductCardData
  productId?: number
}

export interface State {
  displaySidebar: boolean
  displayDropdown: boolean
  displayModal: boolean
  displayNotification: boolean
  displaySearch: boolean
  sidebarView: string
  modalView: string
  modalProps: TModalProps
  notificationView: ENotificationView
  notificationValue: ReactNode
  notificationId: ENotificationIds | null
  userAvatar: string
}

const initialState = {
  displaySidebar: false,
  displayDropdown: false,
  displayModal: false,
  displayNotification: false,
  displaySearch: false,
  modalView: EModalView.Login,
  modalProps: {},
  sidebarView: ESidebarView.Cart,
  notificationView: ENotificationView.Success,
  notificationValue: '',
  notificationId: null,
  userAvatar: '',
}

interface IContext extends State {
  openSidebar: () => void
  closeSidebar: () => void
  toggleSidebar: () => void
  closeSidebarIfPresent: () => void
  openDropdown: () => void
  closeDropdown: () => void
  openNotification: () => void
  closeNotification: () => void
  setNotificationView: (
    view: ENotificationView,
    value: { message: ReactNode; id: ENotificationIds }
  ) => void
  openModal: () => void
  closeModal: () => void
  openSearch: () => void
  closeSearch: () => void
  toggleSearch: () => void
  setModalView: (view: EModalView, props?: TModalProps) => void
  setSidebarView: (view: ESidebarView) => void
  setUserAvatar: (value: string) => void
}

type Action =
  | {
      type: EActionType.OpenSidebar
    }
  | {
      type: EActionType.CloseSidebar
    }
  | {
      type: EActionType.OpenDropdown
    }
  | {
      type: EActionType.CloseDropdown
    }
  | {
      type: EActionType.OpenModal
    }
  | {
      type: EActionType.CloseModal
    }
  | {
      type: EActionType.SetModalView
      view: EModalView
      props: TModalProps
    }
  | {
      type: EActionType.SetSidebarView
      view: ESidebarView
    }
  | {
      type: EActionType.SetUserAvatar
      value: string
    }
  | {
      type: EActionType.SetNotificationView
      view: ENotificationView
      value: {
        message: ReactNode
        id: ENotificationIds
      }
    }
  | {
      type: EActionType.OpenNotification
    }
  | {
      type: EActionType.CloseNotification
    }
  | {
      type: EActionType.OpenSearch
    }
  | {
      type: EActionType.CloseSearch
    }

export const UIContext = createContext<State | any>(initialState)

UIContext.displayName = 'UIContext'

function uiReducer(state: State, action: Action): State {
  switch (action.type) {
    case EActionType.OpenSidebar: {
      return {
        ...state,
        displaySidebar: true,
      }
    }
    case EActionType.CloseSidebar: {
      return {
        ...state,
        displaySidebar: false,
      }
    }
    case EActionType.OpenDropdown: {
      return {
        ...state,
        displayDropdown: true,
      }
    }
    case EActionType.CloseDropdown: {
      return {
        ...state,
        displayDropdown: false,
      }
    }
    case EActionType.OpenModal: {
      return {
        ...state,
        displayModal: true,
        displaySidebar: false,
      }
    }
    case EActionType.CloseModal: {
      return {
        ...state,
        displayModal: false,
      }
    }
    case EActionType.SetModalView: {
      return {
        ...state,
        modalView: action.view,
        modalProps: action.props,
      }
    }
    case EActionType.SetSidebarView: {
      return {
        ...state,
        sidebarView: action.view,
      }
    }
    case EActionType.SetUserAvatar: {
      return {
        ...state,
        userAvatar: action.value,
      }
    }
    case EActionType.SetNotificationView: {
      return {
        ...state,
        notificationView: action.view,
        notificationValue: action.value.message,
        notificationId: action.value.id,
      }
    }
    case EActionType.OpenNotification: {
      return {
        ...state,
        displayNotification: true,
      }
    }
    case EActionType.CloseNotification: {
      return {
        ...state,
        displayNotification: false,
        notificationId: null,
      }
    }
    case EActionType.OpenSearch: {
      return {
        ...state,
        displaySearch: true,
      }
    }
    case EActionType.CloseSearch: {
      return {
        ...state,
        displaySearch: false,
      }
    }
  }
}

export const UIProvider: FC<{ children?: ReactNode }> = (props) => {
  const [state, dispatch] = useReducer(uiReducer, initialState)

  const openSidebar = useCallback(
    () => dispatch({ type: EActionType.OpenSidebar }),
    [dispatch]
  )
  const closeSidebar = useCallback(
    () => dispatch({ type: EActionType.CloseSidebar }),
    [dispatch]
  )
  const toggleSidebar = useCallback(
    () =>
      state.displaySidebar
        ? dispatch({ type: EActionType.CloseSidebar })
        : dispatch({ type: EActionType.OpenSidebar }),
    [dispatch, state.displaySidebar]
  )
  const closeSidebarIfPresent = useCallback(
    () =>
      state.displaySidebar ? dispatch({ type: EActionType.CloseSidebar }) : {},
    [dispatch, state.displaySidebar]
  )

  const openDropdown = useCallback(
    () => dispatch({ type: EActionType.OpenDropdown }),
    [dispatch]
  )
  const closeDropdown = useCallback(
    () => dispatch({ type: EActionType.CloseDropdown }),
    [dispatch]
  )

  const openModal = useCallback(
    () => dispatch({ type: EActionType.OpenModal }),
    [dispatch]
  )
  const closeModal = useCallback(
    () => dispatch({ type: EActionType.CloseModal }),
    [dispatch]
  )

  const setUserAvatar = useCallback(
    (value: string) => dispatch({ type: EActionType.SetUserAvatar, value }),
    [dispatch]
  )

  const setModalView = useCallback(
    (view: EModalView, props: TModalProps) =>
      dispatch({ type: EActionType.SetModalView, view, props }),
    [dispatch]
  )

  const setSidebarView = useCallback(
    (view: ESidebarView) =>
      dispatch({ type: EActionType.SetSidebarView, view }),
    [dispatch]
  )

  const openNotification = useCallback(
    () => dispatch({ type: EActionType.OpenNotification }),
    [dispatch]
  )
  const closeNotification = useCallback(
    () => dispatch({ type: EActionType.CloseNotification }),
    [dispatch]
  )

  const setNotificationView = useCallback(
    (
      view: ENotificationView,
      value: {
        message: ReactNode
        id: ENotificationIds
      }
    ) => dispatch({ type: EActionType.SetNotificationView, view, value }),
    [dispatch]
  )

  const openSearch = useCallback(
    () => dispatch({ type: EActionType.OpenSearch }),
    [dispatch]
  )
  const closeSearch = useCallback(
    () => dispatch({ type: EActionType.CloseSearch }),
    []
  )
  const toggleSearch = useCallback(
    () =>
      state.displaySearch
        ? dispatch({ type: EActionType.CloseSearch })
        : dispatch({ type: EActionType.OpenSearch }),
    [dispatch, state.displaySearch]
  )

  const value = useMemo(
    () => ({
      ...state,
      openSidebar,
      closeSidebar,
      toggleSidebar,
      closeSidebarIfPresent,
      openDropdown,
      closeDropdown,
      openModal,
      closeModal,
      setModalView,
      setSidebarView,
      setUserAvatar,
      setNotificationView,
      openNotification,
      closeNotification,
      openSearch,
      closeSearch,
      toggleSearch,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  return <UIContext.Provider value={value} {...props} />
}

export const useUI = () => {
  const context: IContext = useContext(UIContext)
  if (context === undefined) {
    throw new Error(`useUI must be used within a UIProvider`)
  }
  return context
}

const ManagedUIContext: FC<{ children?: ReactNode }> = ({ children }) => (
  <UIProvider>{children}</UIProvider>
)

export default ManagedUIContext
