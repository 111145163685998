import { Currencies } from '@lib/constants/currencies'
import { DEFAULT_PRICE_LOCALE } from '@lib/constants/language'

export function convertToPrice(price?: number | null, currency?: Currencies) {
  if (price == null) {
    return null
  }

  const euros = price / 100

  return euros.toLocaleString(DEFAULT_PRICE_LOCALE, {
    style: 'currency',
    currency: currency ?? 'EUR',
    currencyDisplay: 'symbol',
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}
