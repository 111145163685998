'use client'

import cn from 'clsx'
import {
  forwardRef,
  ButtonHTMLAttributes,
  JSXElementConstructor,
  useRef,
  FC,
} from 'react'
import mergeRefs from 'react-merge-refs'
import { LoadingDots } from '@components/ui'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string
  className?: string
  variant?: 'primary' | 'secondary' | 'alternate' | 'ghost'
  active?: boolean
  type?: 'submit' | 'reset' | 'button'
  Component?: string | JSXElementConstructor<any>
  width?: string | number
  height?: string | number
  backgroundColor?: string
  border?: string
  borderRadius?: string | number
  loading?: boolean
  disabled?: boolean
  size?: 'lg'
  title?: string
  redBold?: boolean
}

// eslint-disable-next-line react/display-name
const Button: FC<ButtonProps> = forwardRef((props, buttonRef) => {
  const buttonType =
    props.type ?? props.variant === 'primary' ? 'submit' : 'button'

  const {
    className,
    href,
    variant = 'primary',
    children,
    active,
    width,
    height,
    backgroundColor,
    border,
    borderRadius,
    size,
    title,
    redBold,
    type = buttonType,
    loading = false,
    disabled = false,
    style = {},
    Component = 'button',
    ...rest
  } = props
  const ref = useRef<typeof Component>(null)

  const rootClassName = cn(
    'rootButton p-2',
    {
      ['primaryButton']: variant === 'primary',
      ['secondaryButton']: variant === 'secondary',
      ['alternateButton']: variant === 'alternate',
      ['ghostButton']: variant === 'ghost',
    },
    className
  )

  return (
    <Component
      type={type}
      title={title}
      data-variant={variant}
      ref={mergeRefs([ref, buttonRef])}
      className={cn(
        size === 'lg' ? 'w-[252px]' : 'max-md:w-full',
        !height && 'h-[42px]',
        active && 'active',
        rootClassName
      )}
      disabled={loading || disabled}
      style={{
        width,
        height,
        backgroundColor,
        border,
        borderRadius,
        ...style,
      }}
      {...rest}
    >
      {loading && (
        <i className="m-0 flex">
          <LoadingDots />
        </i>
      )}
      {children}
    </Component>
  )
})

export default Button
